<template>
  <b-form-group>
    <label v-if="vlabel">
      {{ vlabel }} 
      <span v-if="vmandatory" class="text-danger mr5">*</span>
    </label>
    <b-badge
      v-if="vinfo"
      variant="info"
      pill
      class="help-badge"
      v-b-tooltip.hover.right="
        vinfo
      "
    >
      <i class="fas fa-question"></i>
    </b-badge>
    <template v-if="editFormValue != editFormId">
      <span class="d-block fw_500">{{ vvalue || defaultMask }}</span>
    </template>
    <template v-else-if="editFormValue == editFormId">
      <div class="d-flex" v-if="this.$slots.buttons">
        <b-form-input 
          v-model="vvalue" 
          :type="vtype"
          :placeholder="vplaceholder" 
          :formatter="v => v.replace(/\s\s\s+/, ' ')"
          :maxlength="vmaxlength"
          :min="vmin"
          :readonly="isreadonly"
        ></b-form-input> 
        <slot name="buttons" />
      </div>
      <template v-else-if="!this.$slots.buttons && !vformatter">
        <b-form-input 
          v-model="vvalue" 
          :type="vtype"
          :placeholder="vplaceholder" 
          :formatter="v => v.replace(/\s\s\s+/, ' ')"
          :maxlength="vmaxlength"
          :min="vmin"
          :readonly="isreadonly"
          :tabindex="tabIndex"
        ></b-form-input> 
      </template>
      <template v-else-if="!this.$slots.buttons && vformatter">
        <b-form-input 
          v-model="vvalue" 
          :type="vtype"
          :placeholder="vplaceholder" 
          :formatter="vformatter"
          :maxlength="vmaxlength"
          :min="vmin"
          :readonly="isreadonly"
        ></b-form-input> 
      </template>
      <template v-if="!visref">
        <VValidate :name="vname || vlabel" v-model="vvalue" :rules="vrules" :vid="vvid" />
      </template>
      <template v-else-if="visref && (vref || '').length === 0">
        <VValidate :name="vname || vlabel" v-model="vvalue" :rules="vrules" :vid="vvid ? vvid : 'ref-01'" />
      </template>
      <template v-else-if="visref && (vref || '').length >= 3">
        <VValidate :name="vname || vlabel" v-model="vvalue" :rules="vrules" :vid="vvid ? vvid : 'ref-02'" />
      </template>
    </template>
  </b-form-group>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    name: String,
    type: {
      type: String,
      default: 'text'
    },
    value: [String, Number],
    label: String,
    info: String,
    rules: [Object, String],
    placeholder: {
      type: String,
      default: ''
    },
    mandatory: {
      type: Boolean,
      default: true
    },
    isref: {
      type: Boolean,
      default: false
    },
    isreadonly: {
      type: Boolean,
      default: false
    },
    refdata: String,
    maxref: {
      type: Number,
      default: null
    },
    veeid: String,
    formatter: {
      type: [Function, Boolean],
      default: false
    },
    tabIndex: {
      type: String,
      default: '',
    },
    maxlength: String,
    minval: String,
    editFormId: {
      type: String,
      default: '',
    },
    editFormValue: {
      type: String,
      default: '',
    },
    defaultMask: {
      type: String,
      default: '-',
    },
  },
  data() {
    return {
      vname: this.name,
      vtype: this.type,
      vlabel: this.label,
      vinfo: this.info,
      vvid: this.veeid,
      vplaceholder: this.placeholder,
      vmandatory: this.mandatory,
      visref: this.isref,
      vmaxref: this.maxref,
      vformatter: this.formatter,
      vmaxlength: this.maxlength,
      vmin: this.minval
    }
  },
  computed: {
    vvalue: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    vref: {
      get() {
        return this.refdata
      },
      set(v) {
        this.$emit('input', v)
      }
    },
    vrules: {
      get() {
        return this.rules
      }
    }
  },
  watch: {
    vref: {
      handler(v) {
        if (!this.maxref) {
          this.vvalue = v;
        } else {
           this.vvalue = _.truncate(v, {
            length: this.vmaxref, // maximum 160 characters
            separator: /,?\.* +/ // separate by spaces, including preceding commas and periods
          }).replace('...', '');
        }
      },
      immediate: false,
    }
  }
}
</script>